<template>
    <div class="individual-order-layout">
        <StepProgress
            v-if="$route.name !== 'individualOrderPayment'"
            :steps="steps"
            :current-step="currentStep"
            class="progress-bar"
        />
        <router-view />
    </div>
</template>

<script>
    import StepProgress from '@/components/Common/StepProgress/StepProgress';
    import { mapActions } from 'vuex';

    export default {
        name: 'IndividualOrderLayout',
        components: { StepProgress },
        data() {
            return {
                currentStep: 0,
            };
        },
        computed: {
            steps() {
                return [
                    this.$t('order.kit'),
                    this.$t('order.delivery'),
                    this.$t('order.buyerInfo'),
                    this.$t('order.submit'),
                ];
            },
        },
        watch: {
            $route: {
                async handler(route) {
                    if (route.name === 'individualOrderKit') {
                        this.currentStep = 0;
                    }

                    if (route.name === 'individualOrderDelivery') {
                        this.currentStep = 1;
                    }

                    if (route.name === 'individualOrderBuyerInfo') {
                        this.currentStep = 2;
                    }

                    if (route.name === 'individualOrderSubmit') {
                        this.currentStep = 3;
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions('individualOrder', ['setWholeIndividualOrder', 'clearIndividualOrderCookies']),
            ...mapActions('orderInformation', ['setBuyerInformation', 'setDeliveryInformation']),
            ...mapActions('mealKit', ['getAllMealKitsWithIO']),
        },
        async mounted() {
            if (this.$route.name !== 'individualOrderPayment') {
                try {
                    await this.getAllMealKitsWithIO();
                } catch (error) {
                    this.$filters.toast(error.message);
                }

                this.setWholeIndividualOrder();
                this.setDeliveryInformation();
                this.setBuyerInformation();
            }
        },
        beforeRouteLeave() {
            this.clearIndividualOrderCookies();
        },
    };
</script>

<style lang="scss" scoped>
    .individual-order-layout {
        padding-bottom: 70px;

        @include media($lg) {
            padding-bottom: 0;
        }

        .progress-bar__wrapper {
            width: 100%;
        }

        .progress-bar {
            width: 80%;
            margin-bottom: 16px;

            @include media($lg) {
                width: 70%;
            }
        }
    }
</style>
