<template>
    <div class="step-progress__wrapper">
        <div class="step-progress__wrapper-before" />
        <div class="step-progress__bar">
            <div
                class="step-progress__step"
                v-for="(step, index) in steps"
                :key="index"
                :class="{
                    'step-progress__step--active': index === currentStep,
                    'step-progress__step--valid': index < currentStep,
                }"
            >
                <div class="step-progress__step-icon">
                    <CheckSvg />
                </div>
                <div class="step-progress__step-label">{{ step }}</div>
            </div>
        </div>
        <div class="step-progress__wrapper-after" />
    </div>
</template>

<script>
    import CheckSvg from '@/assets/icons/checks/check-icon.svg?inline';

    export default {
        name: 'StepProgress',
        components: { CheckSvg },
        props: {
            steps: {
                type: Array,
                default: () => [],
                validator: (val) => val && val.length > 0,
            },
            currentStep: {
                type: Number,
                default: 0,
            },
            activeColor: {
                type: String,
                default: '#00A755',
            },
            passiveColor: {
                type: String,
                default: '#828282',
            },
            lineThickness: {
                type: Number,
                default: 3,
            },
        },
        computed: {
            scaleX() {
                let step = this.currentStep;
                if (step < 1) {
                    step = 0;
                } else if (step >= this.steps.length) {
                    step = this.steps.length - 1;
                }
                return step / (this.steps.length - 1);
            },
            lineHeight() {
                return `${this.lineThickness}px`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .step-progress {
        &__wrapper {
            position: relative;

            width: 92%;
            margin: 0 auto;
        }

        &__wrapper-before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;

            width: 100%;
            height: v-bind(lineHeight);

            background-color: v-bind(passiveColor);

            transform: translateY(-50%) perspective(1000px);
        }

        &__wrapper-after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;

            width: 100%;
            height: v-bind(lineHeight);

            background-color: v-bind(activeColor);

            transform: scaleX(v-bind(scaleX)) translateY(-50%) perspective(1000px);
            transform-origin: left center;
            transition: transform 0.5s ease;
        }

        &__bar {
            @include row-align-center-justify-between;

            width: 100%;
            height: 100px;
        }

        &__step {
            z-index: 2;
            position: relative;

            background-color: v-bind(passiveColor);

            span {
                display: block;

                text-align: center;
                color: v-bind(passiveColor);

                opacity: 1;
                transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
                transition: 0.3s ease;
            }

            &--active {
                span {
                    color: v-bind(activeColor);
                }
                .step-progress__step-label {
                    color: v-bind(activeColor);
                }
                .step-progress__step-icon {
                    opacity: 1;
                }
            }

            &--valid {
                .step-progress__step-icon {
                    opacity: 1;
                    transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
                }

                span {
                    color: v-bind(activeColor);
                    opacity: 0;
                    transform: translate3d(0, 0, 0) scale(2) perspective(1000px);
                }

                .step-progress__step-label {
                    color: v-bind(activeColor);
                }
            }

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;

                width: 20px;
                height: 20px;

                background-color: $white;
                border-radius: 50%;
                border: 3px solid $grey-form-label;

                transform: translate(-50%, -50%) perspective(1000px);
                transition: 0.3s ease;
            }

            &--active:after {
                border: 6px solid $primary;
            }

            &--valid:after {
                border: 12px solid $primary;
                background: $primary;
            }
        }

        &__step-label {
            position: absolute;
            top: calc(100% + 25px);
            left: 50%;

            white-space: nowrap;
            font-size: $font-14;
            font-weight: 700;
            color: gray;

            transition: 0.3s ease;
            transform: translateX(-50%) perspective(1000px);
        }

        &__step-icon {
            position: absolute;
            left: 50%;
            top: 50%;

            opacity: 0;
            transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
            transition: transform 0.3s ease;
        }
    }
</style>
